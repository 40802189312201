@import 'variables';

.chat-container {
  text-align: left;
  border: 1px solid $lightgray;
  border-radius: 4px;
  background-color: $lightgray-3;
  height: 344px;
  overflow-y: auto;
}

.chat-snippet {
  color: $darkgray-2;
  font-family: Lato;
  font-size: 0.813rem;
  line-height: 22px;
  // width: 528px;
  text-align: left;
  padding: 10px;
}

.system-chat {
  display: block;
  margin-bottom: 5px;
}

.visitor-chat {
  display: block;
  margin-bottom: 5px;
  color: $primary-2;
}
.operator-chat {
  display: block;
  margin-bottom: 5px;
  color: $black;
}
