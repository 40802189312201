@include media-breakpoint-down(md) {
  #sidebar {
    height: 100vh;
    .navbar-ham {
      left: calc(110%);
      right: auto;
      top: 1.5rem;
    }

    // &:not(.active) {
    //     .navbar-ham {
    //         background-color: $primary;
    //         color: white;
    //     }
    // }

    &.active {
      left: 0;
      // width: 100%;

      .navbar-ham {
        left: calc(105%);
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  #sidebar {
    position: fixed;
    width: 90%;
    height: 100vh;
    left: -100%;
    // z-index: 10;

    .navbar-ham {
      left: calc(110% + 1rem);
      right: auto;
      top: 1.5rem;
    }

    // &:not(.active) {
    //     .navbar-ham {
    //         background-color: $primary;
    //         color: white;
    //     }
    // }

    &.active {
      left: 0;
      width: 100%;

      .navbar-ham {
        left: calc(100% - 3.5rem);
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .login-form-wrapper {
    width: 100%;
    margin: 0 20px;
  }
  .introjs-fixedTooltip {
    position: absolute !important;
  }

  .introjs-helperLayer.introjs-fixedTooltip {
    left: unset !important;
    width: 100% !important;
  }

  .introjs-tooltipReferenceLayer.introjs-fixedTooltip {
    left: unset !important;
  }
  #introJS-item-0 {
    margin-bottom: 15px !important;
  }
  .body-wrapper {
    overflow: hidden !important;
  }
}

@media only screen and (max-width: 1440px) {
  .collapsible-nav-width {
    width: calc(100% - 232px) !important;
  }
}

@media only screen and (max-height: 700px) {
  .login-form-container {
    height: auto;
    padding: 50px 0px;
    & .tooltip-inner {
      background-color: #333333;
    }
  }
}

@media only screen and (max-width: 700px) {
  .finish-tour-modal {
    width: 100%;
  }
  .introjs-tooltip.introjs-floating {
    max-width: 320px !important;
  }
}

@media only screen and (max-width: 768px) {
  .list-margin-drop-down {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .list-margin-toggle {
    margin-bottom: 30px;
    margin-top: 0px;
  }
  .slider-modal-wrapper .modal-dialog {
    max-width: 100% !important;
  }
  .modal-cancel-btn {
    width: 20px !important;
    height: 20px !important;
    right: -8px !important;
    top: -12px !important;
  }
  .editable-fields-lists {
    > li {
      width: 100%;
      display: inline-block;
      &:nth-child(odd) {
        margin-right: 0px;
      }
      &:nth-child(even) {
        margin-left: 0px;
      }
    }
  }
}
