@import 'variables';
@import 'functions';

.navbar-nav .tab {
  nav {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border-radius: 24px 0 0 0;
    border: none;
    a {
      display: inline-block;
      text-align: center;
      padding: 14px;
      text-decoration: none;
    }
  }

  &-ui {
    position: relative;
    background: $lightgray-2 !important;
    // font-size: 15px;
    font-size: 0.938rem;
    border: none;
    // color: $darkgray-1 !important;
    color: var(--primary-2) !important;

    span {
      display: flex;
      justify-content: space-between;
    }

    &.active,
    &:hover {
      border: none;
      // color: $darkgray-1 !important;
      color: var(--primary-2) !important;
    }

    &.disabled {
      color: var(--primary-5) !important;
    }
    // &:after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   border-radius: 10px 10px 0 0;
    //   height: 6px;
    //   width: 100%;
    //   background-color: $white;
    //   border: none;
    // }
    &:hover {
      color: var(--primary-2) !important;
    }
    &.active {
      color: var(--primary-2) !important;
      & .close-btn {
        color: var(--primary-2);
      }
    }
    &.close-btn {
      font-weight: 600;
      color: lightgray;
    }
    .tab-heading {
      font-family: Lato;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.uncontrolledTabs {
  background: #eaedf1;
  height: 54px;
  .nav-link {
    color: $darkgray-2;
    font-weight: bold;
    line-height: 37px;
    border: 0px;
    &.active {
      background-color: transparent;
      color: var(--primary-2) !important;
      position: relative;
      // &::after {
      //   content: "";
      //   background: var(--primary-2);
      //   height: 4px;
      //   width: 100%;
      //   position: absolute;
      //   left: 0px;
      //   bottom: 0px;
      // }
    }
  }
}
