@import '/src/assets/app-styles/scss/variables';
@import '/src/assets/app-styles/scss/functions';

@mixin boxShadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}
@mixin popoverMenu {
  top: 70px;
  left: 50%;
  z-index: 1;
  color: var(--primary);
  width: 10rem;
  position: absolute;
  @include boxShadow;
  transition: all 0.5s ease;
  padding-top: 0.75rem;
  border-radius: 0.25rem;
  background-color: white;

  &-lg {
    width: 268px;

    @media (max-width: 576px) {
      width: 232px;
    }
  }

  // &:after {
  //   content: '';
  //   width: 10px;
  //   height: 10px;
  //   background: $white;
  //   position: absolute;
  //   left: -4px;
  //   bottom: 22px;
  //   transform: rotate(45deg);
  // }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    li {
      padding: 0 1rem;
      margin-bottom: 0.75rem;

      &:not(:last-child) {
        margin-bottom: 0.375rem;
        // border-bottom: 1px solid #eee;
        padding-bottom: 0.375rem;
      }

      a {
        display: flex;
        font-weight: 600;
        color: var(--primary);
        font-size: 0.8rem !important;
      }

      svg {
        margin-right: 0.25rem;
      }
    }
  }
}
.userModal {
  border-radius: 0.5rem;

  //   width: 480px;
  .modal-content {
    border-radius: 4px; //UIUX-1733
    .modal-body {
      padding: 2rem;
      .navbar-nav {
        width: 100% !important;
        justify-content: center;
        .tab-ui.active {
          border-bottom: 2px solid var(--primary) !important;
        }
      }
      .modal-title {
        color: var(--primary);
        font-weight: 600;
        margin-bottom: 24px;
      }
      .form {
        &-label {
          left: 1rem;
          font-size: pxToRem(13);
          color: $darkgray-1;
          font-family: Lato;
          font-weight: 500;
        }
        &-group {
          margin-bottom: 15px;
          .passEye {
            display: none;
          }
          &.has-value {
            .form-label {
              font-size: pxToRem(10);
              font-family: Lato;
              color: $darkgray-2;
              top: 0.5rem;
            }
            .form-control {
              padding-top: pxToRem(14);
            }
            .passEye {
              display: inline;
              cursor: pointer;
              right: 0.75rem;
              position: absolute;
              top: 0.625rem;
              color: $darkgray-2;
            }
          }
          &.is-invalid {
            .form-control {
              border-color: $danger-1;
            }
            .form-label {
              color: $danger-1;
            }
          }
          .toggleBtn {
            display: inline;
            cursor: pointer;
            right: 0.75rem;
            position: absolute;
            top: 0.625rem;
            color: $darkgray-2;
          }
          .form-control {
            padding: 0 1rem;
            height: 3rem;
            color: $darkgray-1;
            font-family: Lato;
            font-size: pxToRem(13);
            &.lined {
              &:focus + .form-label {
                font-size: 10px;
                top: 0.5rem;
              }
              &.form-control {
                &:focus {
                  padding-top: pxToRem(14);
                }
              }
            }
          }
          .form-control {
            &.lined {
              border-width: 1px;
              border-radius: 0.25rem;
            }
          }
        }
        button {
          margin-top: 1rem;
          span {
            color: $primary-4;
          }
        }
      }
    }
  }
}

.user-modal-header {
  color: $darkgray-1;
  font-weight: bold;
  border: none;
  font-family: Lato;
  // font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.user-modal-header-container {
  background: $lightgray-2;
}

.user-modal-footer {
  background: $lightgray-2;
  border: none;
}
.form-class {
  padding: 20px;
}

.user-modal-body {
  padding: 0px !important;
  .navbar-expand {
    background-color: $lightgray-2;
    margin-top: -2px;
  }
  .tab-styles {
    font-family: Lato;
    // font-size: 15px;
    font-size: 0.938rem;
    line-height: 22px;
    text-align: center;
    font-weight: Bold;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 0px;
    padding-bottom: 4px;
  }
}

.white-background {
  background: #ffffff;
}

.profile-image {
  text-align: center;
  font-weight: Bold;

  .avatar-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  .username {
    margin-top: 10px;
    font-size: large;
  }
}
.imageWrapper {
  position: relative;
}
.change-profile-menu {
  @include popoverMenu;
  .notification-list-item {
    a {
      cursor: pointer;
    }
  }
}
