@import "/src/assets/app-styles/scss/variables";

.profile-nav {
  position: relative;

  .avatar-styles {
    width: 35px;
    height: 35px;
    border-radius: 25px;
  }
}

.disabled-icon {
  pointer-events: none;
  background: $lightgray;
  z-index: auto;
  svg {
    color: $darkgray-3;
  }
}

.customized-scrollbar {
  // width: 100%;
  height: calc(100% - 135px);
  position: relative;
  overflow: hidden;
  .customized-scrollbar-inner {
    position: absolute;
    inset: 0px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    right: 2px;
    left: 2px;
    width: 100%;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--primary);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary);
      border-radius: 6px;
    }
    &:hover {
      right: 2px;
      scrollbar-color: $lightgray-2 transparent;
      scrollbar-width: thin;
      /* Handle on hover */
      ::-webkit-scrollbar-track {
        background: white;
      }
      &::-webkit-scrollbar-thumb {
        background: $lightgray-2;
        border-radius: 6px;
        margin-right: 2px;
        transition: 0.3s ease all;
      }
    }
  }
}
